import { render, staticRenderFns } from "./threeMenupage.vue?vue&type=template&id=6571ca80&scoped=true"
import script from "./threeMenupage.vue?vue&type=script&lang=js"
export * from "./threeMenupage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6571ca80",
  null
  
)

export default component.exports